import React from 'react'
import Layout from '../components/layout'
import SectionHero from '../components/section-hero'
import { Fade } from 'react-awesome-reveal'
import Seo from '../components/seo'
import { usePageHero } from '../hooks/page-hero'

const TermsPage = () => {
	return (
		<Layout>
			<Seo
				title="Terms & Conditions"
				description="Terms & Conditions for Rapid Fire Options."
			/>
			<SectionHero
				image={usePageHero()}
				title1="Terms & Conditions"
				title2="Rapid Fire Options"
				body="Rapid Fire Options is a screener based service based on unusual activities in certain options spanning individual stocks, SPACs, ETFs, Futures, etc. You have the ability to pick which one you want to get into and leave others."
				button="Join Today!"
			/>
			<div className="container-xl bg-base flex justify-center">
				<div className="max-w-6xl py-10">
					<h1 className="pt-10 text-3xl md:text-6xl text-center">
						Terms & Conditions
					</h1>
					<hr className="hr-sm mx-auto my-5 border-primary" />
					<Fade triggerOnce="true">
						<p className="py-3">
							RAPID FIRE OPTIONS (&ldquo;we,&rdquo; &ldquo;us&rdquo; or
							&ldquo;Rapid Fire Options&rdquo;) provides the website known as
							rapidfireoptions.com and other information, including email
							newsletters, Facebook groups and chats, and other such
							communications subject to your compliance with the terms and
							conditions set forth in this Agreement. Collectively, your use of
							the website and other information, including text alerts and
							Discord chat notifications, shall be referred to as the
							&ldquo;Services&rdquo;.
						</p>
						<p className="py-3">
							By using the Services, you agree to be bound by these terms and
							conditions. If you do not agree to these terms and conditions, you
							may not use the Services.
						</p>
						<p className="py-3">
							You must be at least 18 years of age to use the Services. If you
							are not at least 18 years old, you may not access or use the
							Services.
						</p>
						<p className="py-3">We reserve the right at any time to:</p>
						<p className="py-3">
							Change the terms and conditions of this Agreement;
						</p>
						<p className="py-3">
							Change the Services, including eliminating or discontinuing any
							content on or feature of any of the Services; or
						</p>
						<p className="py-3">
							Change any fees or charges for use of the Services.
						</p>
						<p className="py-3">
							Any changes we make will be effective seven (7) days after notice
							of any change is provided to you, which may be done by any means
							including, without limitation, posting on the Services or via
							electronic mail. Your use of the Services after such notice will
							be deemed acceptance of such changes. Be sure to review this
							Agreement periodically to ensure familiarity with the most current
							version. Upon our request, you agree to sign a
							non&#8209;electronic version of this Agreement.
						</p>
						<p className="py-3">
							The Services provided are for general informational purposes only.
							None of the Services is intended as investment, tax, accounting or
							legal advice, as an offer or solicitation of an offer to sell or
							buy, or as an endorsement, recommendation or sponsorship of any
							company, security, or fund. The Services should not be relied upon
							for purposes of transacting securities or other investments. We
							cannot and do not assess or guarantee the suitability or
							profitability of any particular investment, or the potential value
							of any investment or informational source. You bear responsibility
							for your own investment research and decisions, and should seek
							the advice of a qualified securities professional before making
							any investment.
						</p>
						<p className="py-3">
							Renewal: If you are a monthly subscriber, you agree that your
							credit or debit card will be charged the monthly subscription fee
							in effect on the date the charge is made. If your subscription is
							bi-monthly, quarterly or annual, then you agree that your
							subscription will be charged for the respective subscription
							length on the date of the expiration of the initial subscription
							term. All subscription fees are non-refundable and deemed earned
							upon receipt.
						</p>
						<p className="py-3">
							Rapid Fire Options is the copyright and trademark holder of all
							branded properties, programs, services, software, memberships for
							RFO LLC. (&ldquo;Company&rdquo;, &ldquo;We&rdquo;,
							&ldquo;I&rdquo;, &ldquo;Website&rdquo; or &ldquo;Us&rdquo;). The
							term &ldquo;You&rdquo;, &ldquo;User&rdquo;, &ldquo;Visitor&rdquo;,
							&ldquo;Member&rdquo;, &ldquo;Client&rdquo;,
							&ldquo;Customer&rdquo;, &ldquo;Investor&rdquo;,
							&ldquo;Trader&rdquo; refers to any person who access or use the
							Website (RapidFireOptions.com) or Information including purchasers
							and/or users of any of our Programs, Products and/or Services.
							Neither the Company or any of its affiliates, owners, managers,
							employees, shareholders, officers, directors, other personnel,
							representatives, agents or independent contractors (herein
							referred to as the &ldquo;Company&rdquo;, &ldquo;I&rdquo;,
							&ldquo;We&rdquo; or &ldquo;Website&rdquo;) is, in such capacities,
							a licensed financial advisor, registered investment advisor,
							registered broker-dealer or FINRA | SIPC | NFA-member firm. By
							viewing this website or anything made available on or through this
							website, including but not limited to PROGRAMS, PRODUCTS,
							SERVICES, OPT-IN GIFTS, E-BOOKS, VIDEOS, WEBINARS, BLOG POSTS,
							E-NEWSLETTERS, CONSULTATIONS, E-MAILS, SOCIAL MEDIA, SOFTWARE,
							RESEARCH AND/OR OTHER COMMUNICATION (collectively referred to as
							&ldquo;Website&rdquo; or &ldquo;Information&rdquo;), you are
							agreeing to accept all parts of this Disclaimer, Terms of Use and
							Terms &amp; Conditions. Thus, if you do not agree to these below,
							STOP now, and do not access or use this Website.
						</p>
						<p className="py-3">
							Rapid Fire Options (RFO) is not a financial advising, wealth
							management, or other service providing any financial advice. No
							one at RFO has been licensed nor purports to be licensed by any
							governing sanction in order to give financial advice.
						</p>
						<p className="text-2xl font-bold">
							For Informational Purposes Only
						</p>
						<p className="py-3">
							The information provided in or through this website or via the RFO
							discord platform is for educational and informational purposes
							only and solely as a self-help tool for your own use. Accordingly,
							you should not rely solely on the information in making any
							investment. Rather, you should use the information only as a
							starting point for doing additional independent research in order
							to allow you to form your own opinion regarding investments. You
							should always check with your licensed financial and tax advisor
							to determine the suitability of any investment.
						</p>
						<p className="py-3">
							Due to SEC regulations, any trade RECs that are published to
							members or non-members are strictly for educational purposes only.
							Any RECs that are published are simply trades that could be
							winners, but should not be relied upon to win.
						</p>
						<p className="text-2xl font-bold">NOT LEGAL OR FINANCIAL ADVICE.</p>
						<p className="py-3">
							RFO or representatives from RFO are not accountants or financial
							advisors, nor are we holding ourselves out to be. The information
							contained in this website or any other communications is not
							intended and should not be construed as a substitute for legal or
							financial advice that can be provided by your own attorney,
							accountant, and/or financial advisor. Although care has been taken
							in preparing the information provided to you, RFO cannot be held
							responsible for any errors or omissions, and we accept no
							liability whatsoever for any loss or damage you may incur. Always
							seek financial and/or legal counsel relating to your specific
							circumstances as needed for any and all questions and concerns you
							now have, or may have in the future.{' '}
							<strong>
								You agree that the information on our website or any
								communications, including but not limited to e-mail and Discord
								is not legal or financial advice.
							</strong>
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-3">
								PERSONAL RESPONSIBILITY.
							</span>
							<br />
							<br />
							You aim to accurately represent the information provided to us on
							or through our Website. You acknowledge that you are participating
							voluntarily in using our Website and that you are solely and
							personally responsible for your choices, actions and results, now
							and in the future. You accept full responsibility for the
							consequences of your use, or non-use, of any information provided
							on or through this Website, and you agree to use your own judgment
							and due diligence before implementing any idea, suggestion or
							recommendation from my Website to your life, family or business.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">NO GUARANTEES.</span>
							<br />
							<br />
							Our role is to merely communicate when the screener indicates a
							possible trade, but your success depends primarily on your own
							effort, motivation, commitment and follow-through. We cannot
							predict and we do not guarantee that you will attain a particular
							result, and you accept and understand that results differ for each
							individual. Each individual&rsquo;s results depend on his or her
							unique background, dedication, desire, motivation, actions, and
							numerous other factors. You fully agree that there are no
							guarantees as to the specific outcome or results you can expect
							from using the information you receive on or through this Website.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								EARNINGS DISCLAIMER.
							</span>
							<br />
							<br />
							The risk of loss in trading securities, options, futures and forex
							can be substantial. Options involve risk and are not suitable for
							all investors. Consider all relevant risk factors, including their
							own personal financial situation, before trading. Past results of
							any individual or trading system published by any Company,
							including this one, are not indicative of future returns. Any
							earnings or income statements or examples shown through our
							Website are only estimates of what might be possible now or in the
							future. There can be no assurance as to any particular financial
							outcome based on the use of our Website. Such set-ups are not
							solicitations of any kind or order to buy or sell a financial
							security and should not be construed as investment advice under
							any circumstances. RFO will not be held liable for losses
							resulting from information or advice presented in this website (or
							third party); the use of such information is entirely at the risk
							of the user. RFO assumes no responsibility or liability for your
							trading and investment results whatsoever and under any
							circumstances. You agree that RFO is not responsible for your
							earnings, the success or failure of your personal or business
							decisions, the increase or decrease of your finances or income
							level, or any other result of any kind that you may have as a
							result of information presented to you through our Website. You
							are solely responsible for your results.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">TESTIMONIALS.</span>
							<br />
							<br />
							We may present real world experiences, testimonials, and insights
							about other people&rsquo;s experiences with my Website for
							purposes of illustration only. These results may not be typical.
							The testimonials, examples, and photos used are of actual clients.
							In some cases, photos of clients and names are removed or changed
							for privacy purposes. We disclose that some photos or names of
							such persons in reviews are not actual consumers of the advertised
							product. However, we do not alter, modify, or adjust the
							testimonial or endorsement given by the client or customer. They
							are not intended to represent or guarantee that current or future
							clients will achieve the same or similar results; rather, these
							testimonials represent what is possible for illustrative purposes
							only.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								ASSUMPTION OF RISK.
							</span>
							<br />
							<br />
							As with all situations, there are sometimes unknown individual
							risks and circumstances that can arise during use of the Website
							or other communications that cannot be foreseen that can influence
							or reduce results. You understand that any mention of any
							suggestion or recommendation on or through my Website is to be
							taken at your own risk, with no liability on my part, recognizing
							that there is a rare chance that illness, injury or even death
							could result, and you agree to assume all risks.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								LIMITATION OF LIABILITY.
							</span>
							<br />
							<br />
							By using this Website, you agree to absolve us of any liability or
							loss that you or any other person may incur from use of the
							information, products or materials that you request or receive
							through or on my Website. You agree that we will not be liable to
							you, or to any other individual, company or entity, for any type
							of damages, including direct, indirect, special, incidental,
							equitable or consequential loss or damages, for use of or reliance
							on my Website. You agree that we do not assume liability for
							accidents, delays, injuries, harm, loss, damage, death, lost
							profits, personal or business interruptions, misapplication of
							information, physical or mental disease or condition or issue, or
							any other type of loss or damage due to any act or default by us
							or anyone acting as our agent, consultant, affiliate, joint
							venture partner, employee, shareholder, director, staff, team
							member, or anyone otherwise affiliated with my business or me, who
							is engaged in delivering content on or through this Website.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								INDEMNIFICATION AND RELEASE OF CLAIMS.
							</span>
							<br />
							<br />
							You hereby fully and completely hold harmless, indemnify and
							release us and any of our agents, consultants, affiliates, joint
							venture partners, employees, shareholders, directors, staff, team
							members, or anyone otherwise affiliated with my business or me
							from any and all causes of action, allegations, suits, claims,
							damages, or demands whatsoever, in law or equity, that may arise
							in the past, present or future that is in any way related to our
							Website or any other communications from RFO, including but not
							limited to emails and social media.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">NO WARRANTIES.</span>
							<br />
							<br />
							WE MAKE NO WARRANTIES RELATED TO THE PERFORMANCE OR OPERATION OF
							MY WEBSITE . WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
							EXPRESS OR IMPLIED, AS TO THE INFORMATION, CONTENT, MATERIALS,
							PROGRAMS, PRODUCTS OR SERVICES INCLUDED ON OR THROUGH THE WEBSITE.
							TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, WE DISCLAIM ALL
							WARRANTIES, EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF
							MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. YOU
							EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICE IS AT
							YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN &ldquo;AS IS&rdquo;
							AND &ldquo;AS AVAILABLE&rdquo; BASIS.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								ERRORS AND OMISSIONS.
							</span>
							<br />
							<br />
							Although every effort is made to ensure the accuracy of
							information shared on or through this Website or any other
							communications from RFO, including but not limited to emails and
							social media, the information may inadvertently contain
							inaccuracies or typographical errors. You agree that we are not
							responsible for the views, opinions, or accuracy of facts
							referenced on or through our Website, or of those of any other
							individual or company affiliated with our business in any way.
							Because scientific, technology and business practices are
							constantly evolving, you agree that we are not responsible for the
							accuracy of our Website, or for any errors or omissions that may
							occur.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">NO ENDORSEMENT.</span>
							<br />
							<br />
							References or links on my Website to the information, opinions,
							advice, programs, products or services of any other individual,
							business or entity does not constitute our formal endorsement. We
							are merely sharing information for your own self-help only. We are
							not responsible for the website content, blogs, e-mails, videos,
							social media, programs, products and/or services of any other
							person, business or entity that may be linked or referenced on our
							Website or any other communications from RFO, including but not
							limited to emails and social media. Conversely, should our Website
							link appear in any other individual&rsquo;s, business&rsquo;s or
							entity&rsquo;s website, program, product or services, it does not
							constitute our formal endorsement of them, their business or their
							website either.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">AFFILIATES.</span>
							<br />
							<br />
							From time to time, we may promote, affiliate with, or partner with
							other individuals or businesses whose programs, products and
							services align with us. In the spirit of transparency, we want you
							to be aware that there may be instances when we promote, market,
							share or sell programs, products or services for other partners
							and in exchange we may receive financial compensation or other
							rewards. At the same time, you agree that any such promotion or
							marketing does not serve as any form of endorsement whatsoever.
							You are still required to use your own judgment to determine that
							any such program, product or service is appropriate for you. You
							are assuming all risks, and you agree that I am not liable in any
							way for any program, product or service that I may promote,
							market, share or sell on or through my Website.
							<br />
							By using our Website you are agreeing to all parts of the above
							Disclaimer. If you have any questions about this Disclaimer,
							please contact us.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">TERMS OF USE</span>
							<br />
							<br />
							These Terms of Use (&ldquo;Terms of Use&rdquo;) state how you may
							use our Programs, Products and Services and Program Materials, and
							their content. Please read these Terms of Use carefully. We
							reserve the right to change these Terms of Use from time to time.
							By using any of our Programs, Products and Services you are
							agreeing to the Terms of Use as they appear and are legally bound
							by them, whether or not you have read them. If at any time you do
							not agree with these Terms of Use, please do not use our Programs,
							Products and Services.
							<br />
							These Terms of Use require the use of arbitration on an individual
							basis to resolve disputes, rather than jury trials, and limit the
							remedies available to you in the event of a dispute. You fully
							understand and agree that by enrolling in, purchasing and/or using
							any of our Programs, Products, Services and Program Materials that
							you are waiving certain legal rights and you are voluntarily
							agreeing to do so.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">USE AND CONSENT</span>
							<br />
							<br />
							By purchasing or using any of our Programs, Products or Services,
							you agree to abide by these Terms of Use as well as our
							Disclaimer, Terms and Conditions and Privacy Policy, and any other
							terms and conditions that may apply, and are you are required to
							act in accordance with them. Accessing, purchasing or using our
							Programs, Products, Services or Program Materials, in any manner
							constitutes use of the Program, Products, Services and Program
							Materials, and your agreement to be bound by these Terms of Use.
							All of our Programs, Products, Services and Program Materials are
							intended solely for users who are eighteen (18) years of age or
							older. Any registration by, use of or access to any Program,
							Product, Service or Program Materials by anyone under age 18 is
							unauthorized, unlicensed and in violation of these Terms of Use.
							By accessing or using our Programs, Products, or Services or our
							Program Materials, you represent and warrant that you are at least
							18 years old.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								DISCORD/TELEGRAM USER GROUP ACCESS
							</span>
							<br />
							<br />
							If you sign up for our “Live Trader” program, you will be given
							access to our chat based Discord group or Telegram group (Discord
							is currently being migrated to Telegram). These groups will allow
							you to view RECs and informational material that is published. The
							whole Discord/Telegram group is run as an educational service and
							we expect you to honor the nature of the service.
							<br />
							<br />
							<span className="italic">
								No tolerance policy for cross-promotion
							</span>
							<br />
							We have a zero tolerance policy for cross-promoting any services
							like your own Social Media groups or servers on other platforms
							like Discord, Facebook, Slack, Whatsapp, Google Groups or any
							similar service. Promoting your own services or groups on
							Discord/Telegram or via DMS to our server members will result in
							immediate termination of your membership and any legal actions
							available to us.nation of your membership and any legal actions
							available to us.
							<br />
							<br />
							We may terminate your right to access or use the Information or
							the Website at any time, with or without notice, for conduct that
							is in br/each of this Agreement, for conduct that we believe is
							harmful to our business, or for conduct where your use of the
							Information and/or Website is harmful to any other party. If you
							do not agree to these conditions, STOP reading now, and do not
							access or use the Information and/or the Website.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								INTELLECTUAL PROPERTY RIGHTS
							</span>
							<br />
							<br />
							Our Limited License to You. Our Programs, Products, and Services
							and all the Program Materials are our property and/or our
							affiliates or licensors, and are protected by copyright,
							trademark, and other intellectual property laws.
							<br />
							The content in our Programs, Products and Services is solely owned
							by or licensed to us, unless otherwise indicated. This content
							includes, but is not limited to, the design, layout, look,
							appearance, graphics of our Program Materials or any other
							material or aspects of materials provided by us to you.
							Reproduction is prohibited other than in accordance with the
							copyright notice, which forms part of these Terms of Use. If you
							purchase or access any of our Program Materials through our
							Programs, Products or Services, you will be considered our
							Licensee. For the avoidance of doubt, all content obtained through
							us is our property, and you are granted a revocable,
							non-transferable license for personal, non-commercial use only,
							limited to you only. This means that you may not use our Programs,
							Products or Services or the Program Materials in a manner that
							constitutes an infringement of our rights or in a manner that has
							not been authorized by us.
							<br />
							You are being granted a limited license to use our Program,
							Products and Services, and Program Materials with permission and
							restrictions. This means that when you purchase a Program, Product
							or Service from our Website or otherwise, you are purchasing the
							limited right to use the Program Materials in the form that is
							provided by us to you with certain conditions as specified in
							these Terms of Use.
							<br />
							You are permitted to use our Programs, Products, Services and
							Program Materials as follows:
							<br />
							<strong>
								You may download and/or print Program Materials for your own
								personal use in your business.
								<br />
								However, you are not permitted to share, sell, reprint or
								republish any other of our Program Materials, including
								handouts, for resale or mass reproduction purposes for your own
								business use.
							</strong>
							<br />
							Any trademarks, taglines, and logos displayed on Program Materials
							are trademarks belonging to us. All trademarks reproduced in this
							Website, which are not the property of, or licensed to us, are
							acknowledged on the Website. Any use including framing, meta tags
							or other text utilizing these trademarks, or other trademarks
							displayed, is strictly prohibited without our express written
							consent, or permission granted herein.
							<br />
							For those trademarks, taglines, and logos for which you are
							granted permission to use, the trademark indicia must be included
							at all times. Any marketing or promotional tools and/or Program,
							Product or Service titles or any other title or information of
							ours bearing the trademark symbols (&trade;) or &reg; may not be
							used by you for any reason without our express written permission.
							<br />
							All rights not expressly granted in these terms or any express
							written license, are reserved by us.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								Information You Are Prohibited from Sharing with Others.
							</span>
							<br />
							<br />
							As a Licensee, you understand and acknowledge that our Programs,
							Products and Services and the Program Materials have been created,
							developed or obtained by us through the investment of significant
							time, effort and expense, and that this information is a valuable,
							special and unique asset of ours which needs to be protected from
							improper and/or unauthorized use.
							<br />
							When you enroll in or purchase our Programs, Products or Services,
							you agree that you are clearly and expressly prohibited from doing
							the following:
							<br />
						</p>
						<ul className="list-disc list-inside pl-5">
							<li>
								You will not copy, share or steal our Programs, Products,
								Services, or Program Materials, or any parts of them.
							</li>
							<li>
								You will not in any way use, copy, adapt or represent any of our
								Programs, Products, Services or Program Materials in any way as
								if they are yours or created by you.
							</li>
							<li>
								You will not engage in improper and/or unauthorized use of our
								Programs, Products, Services and Program Materials. Improper and
								unauthorized use includes but is not limited to modifying,
								copying, reproducing, republishing, uploading, posting,
								transmitting, translating, selling, creating derivative works,
								exploiting, or distributing in any manner or medium (including
								by email or other electronic means) any Program Materials or any
								other information accessed or purchased through our Programs,
								Products or Services, or any other communications provided by us
								for your own personal use, business/commercial use or in any way
								that earns you money.
							</li>
							<li>
								You will not duplicate, share, trade, sell, or otherwise
								distribute our Programs, Products, Services or Program Materials
								to any other person, for their personal use, business/commercial
								use or in any way that earns them money, whether it was known to
								you or not at the time that you shared the information that
								their intention was to use the Program Materials for their own
								personal use or business/commercial use. This means you cannot
								share or sell or any part of our Programs, Products and Services
								or Program Materials to someone else so they can copy and/or use
								them for their own personal use, business/commercial use or in
								any way that earns them money. You are the only one granted a
								limited license to use our Program, Product, Service, and
								Program Materials.
							</li>
							<li>
								You will not violate our intellectual property rights, including
								copyright and trademark rights. Downloading, printing, or
								otherwise using our Programs, Products, Services or Program
								Materials for your own training purposes in no way gives you any
								copyright, trademark, intellectual property or ownership rights
								of our Program, Product, Service or Program Materials.
							</li>
							<li>
								You will not reprint or republish any part of our Programs,
								Products, Services or Program Materials for publication or
								compilation into your own products, programs, services or
								program materials for your own personal use or
								business/commercial use or in any way that earns you money.
							</li>
							<li>
								You will not use our Programs, Products, Services or Program
								Materials in a manner that constitutes an infringement of our
								rights or in a manner that has not been authorized by us through
								our prior written consent.
							</li>
							<li>
								You may not engage in improper and/or unauthorized use of our
								Program Materials or any other information related to our
								Programs, Products, or Services. Unless otherwise explicitly
								authorized in these Terms of Use, improper and/or unauthorized
								use includes but is not limited to modifying, copying,
								reproducing, republishing, uploading, posting, transmitting,
								translating, selling, creating derivative works, exploiting, or
								distributing in any manner or medium (including by email or
								other electronic means) for commercial use, to any other person
								in a way that earns them money, any Program Materials or any
								other information accessed or purchased through our Programs,
								Products or Services or any other communications provided by us
								to you promoting or relating to the Programs, Products or
								Services.
							</li>
						</ul>
						<p>
							<br />
							You understand and agree that engaging in the prohibited use or
							the improper and/or unauthorized use of our Programs, Products or
							Services or Program Materials as set forth in these Terms of Use
							is considered theft and stealing and we retain the right to
							prosecute theft to the full extent of the law.
							<br />
							<br />
							You agree and understand that prohibited use, improper and/or
							unauthorized use may give rise to a civil claim for damages and/or
							be a criminal offense.
							<br />
							Limitations on Linking and Framing. You may establish a hypertext
							link to our Website or Content so long as the link does not state
							or imply any sponsorship, endorsement by, or ownership by in our
							Website or Content and does not state or imply that we are have
							sponsored, endorsed or have ownership rights in your website.
							However, you may not frame or inline link our Content without our
							written permission.
							<br />
							Your License to Us. By posting or submitting any material on or
							through our Programs, Products, Services or Program Materials,
							such as comments, posts, photos, images or videos or other
							contributions, you are representing that you are the owner of all
							such materials and you are at least 18 years old.
							<br />
							<br />
							When you submit to us or post any comment, photo, image, video or
							any other submission for use on or through our Website, you are
							granting us, and anyone authorized by us, an unlimited,
							royalty-free, perpetual, irrevocable, non-exclusive, unrestricted,
							worldwide license to use, copy, modify, transmit, sell, exploit,
							create derivative works from, distribute, and/or publicly perform
							or display such contributions, in whole or in part, in any manner
							or medium, now known or developed in the future, for any purpose,
							and granting us the right to make it part of our current or future
							Programs, Products, Services and/or Program Materials. This right
							includes granting us use or exploitation of proprietary rights or
							intellectual property rights like copyright, trademark, service
							mark, trade secrets, patent rights or any other of your
							intellectual property rights under any relevant jurisdiction
							without any further permission from you or compensation by us to
							you.
							<br />
							<br />
							You also grant us, and anyone authorized by us, the right to
							identify you as the author of any of your comments, posts, photos,
							images, videos or other contributions by name, email address, or
							screen name. You acknowledge that we have the right but not the
							obligation to use and display any contributions from you of any
							kind and that we may elect to cease the use and display of any
							such contributions on our Programs, Products, Services and/or
							Program Materials at any time for any reason whatsoever.
							<br />
							Media Release. By participating in our Programs, Products and
							Services, and using our Program Materials, including our any
							social media profile or community, you consent to photographs,
							videos, and/or audio recordings that may be made that may contain
							you, your voice and/or your likeness. In our sole discretion, we
							reserve the right to use these photographs, videos, and or/audio
							recordings and/or any other materials submitted by you to us in
							connection with your participation in our Program, Product or
							Services in our current or future Programs, Products or Services,
							and/or our marketing or promotional efforts, without compensation
							to you at any time, now or at any time in the future.
							<br />
							<br />
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								Request for Permission to Use Content
							</span>
							<br />
							<br />
							We very clearly state that you may not use our Programs, Products,
							Services or Program Materials, in whole or in part, in any way
							that is contrary to these Terms of Use unless we have given you
							specific written permission to do so.
							<br />
							If you are granted permission by us, you agree to use the specific
							Content that we allow and ONLY in the ways for which we have given
							you our written permission.
							<br />
							If you choose to use the content in ways that we do not
							specifically give you written permission, you agree now that you
							will be treated as if you had copied, duplicated and/or stolen
							such Content from us, and you consent to immediately stop using
							such Content and to take whatever actions as we may request and by
							the methods and in the time frame that we prescribe to protect our
							intellectual property and ownership rights in our Programs,
							Products, Services or Program Materials.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">Security.</span>
							<br />
							<br /> When you apply for, enroll in, purchase or use our
							Programs, Products or Services, or Program Materials we may seek
							and collect personal data and information including but not
							limited to your name, e-mail address, phone number, billing
							information, credit card or payment information, demographic
							information, preferences, interests, or other
							personally-identifying information (&ldquo;Confidential
							Information&rdquo;).
							<br />
							By providing such Confidential Information to us, you grant us
							permission to use and store such Confidential Information. We, in
							turn, will use our best efforts to keep your Confidential
							Information safe, secure and confidential. We take precautions to
							protect such Confidential Information. When you submit
							Confidential Information via our Program, Product, Service or
							Program Materials, we take measures to protect the security of
							your Confidential Information both online and offline.
							<br />
							However, due to the nature of the Internet, we cannot completely
							ensure or warrant the security of your Confidential Information or
							of any other data or information transmitted to us or through our
							services; therefore submitting Confidential Information, data or
							other information is done at your own risk.
							<br />
							We have security measures in place to prevent the loss, misuse,
							and alteration of the information that is obtained from you, but
							we make no assurances about our ability to prevent any such loss,
							misuse, to you or to any third party arising out of any such loss,
							misuse, or alteration.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								Personal Responsibility and Assumption of Risk
							</span>
							<br />
							<br />
							As a Licensee, you agree that you are using your own judgment in
							using our Programs, Products, Services and Program Materials and
							you agree that you are doing so at your own risk. Our Programs,
							Products, Services and Program Materials are for informational and
							educational purposes only. You agree and understand that you
							assume all risks and no results are guaranteed in any way related
							to our Programs, Products, Services and Program Materials. Our
							Programs, Products, Services and Program Materials are merely to
							provide you with education and tools to help you make your own
							decisions for yourself. You are solely responsible for your
							actions, decisions and results based on the use, misuse or non-use
							of our Programs, Products, Services and Program Materials.
							<br />
							We take every precaution to protect our Programs, Products,
							Services and Program Materials. However, due to the nature of the
							Internet, we cannot completely ensure or warrant the security of
							the Programs, Products, Services and Program Materials or the
							contributions or information transmitted to us on or through our
							Website or our Programs, Products, Services and Program Materials.
							Submitting contributions or information on our through our
							Programs, Products, Services and Program Materials is done
							entirely at your own risk. We make no assurances about our ability
							to prevent any such loss or damage to you or to any other person,
							company or entity arising out of use of our Programs, Products,
							Services and Program Materials and you agree that you are assuming
							such risks.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								General Disclaimer.
							</span>
							<br />
							<br />
							Our Programs, Products, Services, and Program Materials are for
							informational and educational purposes only. To the fullest extent
							permitted by law, we expressly exclude any liability for any
							direct, indirect or consequential loss or damage incurred by you
							or others in connection with our Programs, Products, Services, and
							Program Materials, including without limitation any liability for
							any accidents, delays, injuries, harm, loss, damage, death, lost
							profits, personal or business interruptions, misapplication of
							information, physical or mental disease, condition or issue,
							physical, mental, emotional, or spiritual injury or harm, loss of
							income or revenue, loss of business, loss of profits or contracts,
							anticipated savings, loss of data, loss of goodwill, wasted time
							and for any other loss or damage of any kind, however and whether
							caused by negligence, br/each of contract, or otherwise, even if
							foreseeable. You specifically acknowledge and agree that we are
							not liable for any defamatory, offensive or illegal conduct of any
							other Program, Product, Service or Program Materials participant
							or user, including you.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								Medical Disclaimer.
							</span>
							<br />
							<br />
							Our Programs, Products, Services, and Program Materials are not to
							be perceived as or relied upon in any way as medical advice or
							mental health advice. The information provided through our
							Programs, Products, Services, and Program Materials is not
							intended to be a substitute for professional medical advice,
							diagnosis or treatment that can be provided by your own physician,
							nurse practitioner, physician assistant, therapist, counselor,
							mental health practitioner, licensed dietitian or nutritionist,
							member of the clergy, or any other licensed or registered health
							care professional. Do not disregard professional medical advice or
							delay seeking professional advice because of information you have
							read in our Programs, Products, Services, and Program Materials,
							or received from us. Do not stop taking any medications without
							speaking to your physician, nurse practitioner, physician
							assistant, mental health provider or other health care
							professional. If you have or suspect that you have a medical or
							mental health issue, contact your own health care provider
							promptly. We are not providing health care, medical or nutrition
							therapy services or attempting to diagnose, treat, prevent or cure
							in any manner whatsoever any physical ailment, or any mental or
							emotional issue, disease or condition. We are not giving medical,
							psychological, or religious advice whatsoever.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								Legal and Financial Disclaimer.
							</span>
							<br />
							<br />
							Our Programs, Products, Services, and Program Materials are not to
							be perceived or relied upon in any way as business, financial or
							legal advice. The information provided through our Programs,
							Products, Services, and Program Materials is not intended to be a
							substitute for professional advice that can be provided by your
							own accountant, lawyer, or financial advisor. We are not giving
							financial or legal advice in any way. You are hereby advised to
							consult with your own accountant, lawyer or financial advisor for
							any and all questions and concerns you have regarding your own
							income and taxes pertaining to your specific financial and/or
							legal situation. You agree that we are not responsible for your
							earnings, the success or failure of your business decisions, the
							increase or decrease of your finances or income level, or any
							other result of any kind that you may have as a result of
							information presented to you through our Programs, Products,
							Services, and Program Materials. You are solely responsible for
							your results.
							<br />
							<br />
							You acknowledge that neither the Company or any of its affiliates,
							owners, managers, employees, shareholders, officers, directors,
							other personnel, representatives, agents or independent
							contractors is, in such capacities, a licensed financial advisor,
							registered investment advisor, registered broker-dealer or FINRA |
							SIPC | NFA-member firm. Examples presented on Company&rsquo;s
							website including video tutorials, indicators, strategies,
							columns, articles, emails, newsletters, and all other content of
							Company&rsquo;s products (collectively, the
							&ldquo;Information&rdquo;) are provided for informational and
							educational purposes only. Such set-ups are not solicitations of
							any kind or order to buy or sell a financial security and should
							not be construed as investment advice under any circumstances. The
							Company (Rapid Fire Options) will not be held liable for losses
							resulting from information or advice presented in this website (or
							third party); the use of such information is entirely at the risk
							of the user. The Company (Rapid Fire Options) assumes no
							responsibility or liability for your trading and investment
							results whatsoever and under any circumstances. Statements on the
							Company&rsquo;s website, or in its publications and information,
							are made as of the date stated and are subject to change without
							notice. The risk of loss in trading securities, options, futures
							and forex can be substantial. Options involve risk and are not
							suitable for all investors. Consider all relevant risk factors,
							including their own personal financial situation, before trading.
							Past results of any individual or trading system published by
							Company are not indicative of future returns.
							<br />
							<br />
							It should not be assumed that the methods, techniques, or
							indicators presented in these products and/or services will be
							profitable or that they will not result in losses. Past results of
							any individual trader or trading system published by Company are
							not indicative of future returns by that trader or system and are
							not indicative of future returns which may be realized by you. In
							addition, the Information provided by Company is provided for
							informational and educational purposes only and should not be
							construed as investment advice under any circumstances. Examples
							presented on Company&rsquo;s website are for educational purposes
							only. Such set-ups are not solicitations of any order to buy or
							sell a financial security. Accordingly, you should not rely solely
							on the Information in making any investment. Rather, you should
							use the Information only as a starting point for doing additional
							independent research in order to allow you to form your own
							opinion regarding investments. You should always check with your
							licensed financial advisor and tax advisor to determine the
							suitability of any investment.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								Earnings Disclaimer.
							</span>
							<br />
							<br />
							You acknowledge that we have not and do not make any
							representations as to the health physical, mental, emotional,
							spiritual or health benefits, future income, expenses, sales
							volume or potential profitability or loss of any kind that may be
							derived as a result of your participation in this Program,
							Product, Services or Program Materials. We cannot and do not
							guarantee that you will attain a particular result, positive or
							negative, financial or otherwise, through the use of our Programs,
							Products, Services and Program Materials and you accept and
							understand that results differ for each individual. We also
							expressly disclaim responsibility in any way for the choices,
							actions, results, use, misuse or non-use of the information
							provided or obtained through any of our Programs, Products,
							Services or Program Materials. You agree that your results are
							strictly your own and we are not liable or responsible in any way
							for your results.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								Warranties Disclaimer.
							</span>
							<br />
							<br />
							WE MAKE NO WARRANTIES AS TO OUR PROGRAMS, PRODUCTS, SERVICES, OR
							PROGRAM MATERIALS. YOU AGREE THAT PROGRAMS, PRODUCTS, SERVICES, OR
							PROGRAM MATERIALS ARE PROVIDED &ldquo;AS IS&rdquo; AND WITHOUT
							WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. TO THE FULLEST
							EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE DISCLAIM ALL
							WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
							IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
							PURPOSE, AND NON-INFRINGEMENT. WE DO NOT WARRANT THAT THE
							PROGRAMS, PRODUCTS, SERVICES, OR PROGRAM MATERIALS WILL BE
							FUNCTIONAL, UNINTERRUPTED, CORRECT, COMPLETE, APPROPRIATE, OR
							ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT ANY PART OF
							THE WEBSITE, CONTENT ARE FREE OF VIRUSES OR OTHER HARMFUL
							COMPONENTS. WE DO NOT WARRANT OR MAKE ANY REPRESENTATIONS
							REGARDING THE USE OR THE RESULTS OF THE USE OF OUR PROGRAM,
							PRODUCT OR SERVICES MATERIALS OR ON THIRD-PARTY WEBSITES IN TERMS
							OF THEIR CORRECTNESS, ACCURACY, TIMELINESS, RELIABILITY OR
							OTHERWISE.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								Technology Disclaimer.
							</span>
							<br />
							<br />
							We try to ensure that the availability and delivery of our
							Programs, Products, Services and Program Materials is
							uninterrupted and error-free, including our content and
							communications through methods like our Website, member forum,
							private Facebook groups, e-mail communications, videos, audio
							recordings, webinars, recorded webinars, teleseminars, recorded
							teleseminars, emails, downloadable Mp3 audio files, downloadable
							PDF printed transcripts, downloadable PDF handouts/slides,
							handouts, ebooks, or any other materials provided by us to you.
							However, we cannot guarantee that your access will not be
							suspended or restricted from time to time, including to allow for
							repairs, maintenance or updates, although, of course, we will try
							to limit the frequency and duration of suspension or restriction.
							To the fullest extent permitted by law, we will be not be liable
							to you for damages or refunds, or for any other recourse, should
							our Programs, Products, Services or Program Materials become
							unavailable or access to the them becomes slow or incomplete due
							to any reason, such as system back-up procedures, internet traffic
							volume, upgrades, overload of requests to the servers, general
							network failures or delays, or any other cause which may from time
							to time make our Programs, Products, Services or Program Materials
							inaccessible to you.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								Errors and Omissions.
							</span>
							<br />
							<br />
							We make no warranty or guarantee as to the accuracy, timeliness,
							performance, completeness or suitability of the information in our
							Programs, Products, Services, and Program Materials. Every effort
							has been made to present you with the most accurate, up-to-date
							information, but because the nature of scientific research is
							constantly evolving, we cannot be held responsible or accountable
							for the accuracy of our content. You acknowledge that such
							information may contain inaccuracies or errors and we are not
							liable for any such inaccuracies or errors to the fullest extent
							permitted by law.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								Links to Other Websites.
							</span>
							<br />
							<br />
							We may provide links and pointers to other websites maintained by
							third parties that may take you outside of our Programs, Products,
							Services or Program Materials. These links are provided for your
							convenience and the inclusion of any link in our Programs,
							Products, Services or Program Materials to any other website does
							not imply our endorsement, sponsorship, or approval of that
							website or its owner. We do not endorse and we are not responsible
							for the views, opinions, facts, advice, or statements provided by
							external resources referenced in our Website or its Content, or
							their accuracy or reliability. We assume no responsibility for
							errors or omissions caused by other websites that may be included
							in our Programs, Products, Services or Program Materials. We have
							no control over the contents or functionality of those websites
							and so we accept no responsibility for any loss, damage, or
							otherwise that may arise from your use of them and therefore we do
							not guarantee the accuracy, completeness, or usefulness of any
							other website or their content. It is your responsibility to
							review the terms and conditions and privacy policies of those
							linked websites to confirm that you understand and agree with
							those policies.
							<br />
							By purchasing and/or using our Programs, Products, Services or
							Program Materials in any way or for any reason, you also
							implicitly agree to our full disclaimer, terms, conditions, etc.
							found on this page.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								Indemnification, Limitation of Liability and Release of Claims
								Indemnification.
							</span>
							<br />
							<br />
							You agree at all times to defend, indemnify and hold harmless our
							Company, as well as any of our affiliates, agents, contractors,
							officers, directors, shareholders, employees, joint venture
							partners, successors, transferees, assignees, and licensees, as
							applicable, from and against any and all claims, causes of action,
							damages, liabilities, costs and expenses, including legal fees and
							expenses, arising out of or related to our Programs, Products,
							Services or Program Materials, or your br/each of any obligation,
							warranty, representation or covenant set forth in these Terms and
							Conditions or in any other agreement with us.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								Limitation of Liability.
							</span>
							<br />
							<br />
							We will not be held responsible or liable in any way for the
							information, products or materials that you request or receive
							through or on our Programs, Products, Services or Program
							Materials. We do not assume liability for accidents, delays,
							injuries, harm, loss, damage, death, lost profits, personal or
							business interruptions, misapplication of information, physical or
							mental disease, condition or issue, or otherwise, due to any act
							or default of anyone or any business, whether owners, staff,
							agents, joint venture partners, contractors, vendors, affiliates
							or otherwise, affiliated with us. We do not assume liability for
							any owners, staff, agents, joint venture partners, contractors,
							vendors, affiliates or otherwise who are engaged in rendering our
							Programs, Products, Services or Program Materials, or in any way
							or in any location. In the event that you use our Programs,
							Products, Services or Program Materials or any other information
							provided by us or affiliated with us, we assume no responsibility.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								Release of Claims.
							</span>
							<br />
							<br />
							In no event will we be liable to any party for any type of direct,
							indirect, special, incidental, equitable or consequential damages
							for any use of or reliance on our Programs, Products, Services or
							Program Materials, or on those affiliated with us in any way, and
							you hereby release us from any and all claims; including, without
							limitation, those related to lost profits, personal or business
							interruptions, personal injuries, accidents, misapplication of
							information, or any other loss, physical or mental disease,
							condition or issue, or otherwise, even if we are expressly advised
							of the possibility of such damages or difficulties.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">Your Conduct.</span>
							<br />
							<br />
							You are agreeing that you will not use our Programs, Products,
							Services or Program Materials in any way that causes or is likely
							to cause the Programs, Products, Services or Program Materials, or
							access to them either to be interrupted, damaged or impaired in
							any way. You understand that you are solely responsible for all
							electronic communications and content sent from your computer to
							this Website and its Content and to us.
							<br />
							<br />
							You must use the Programs, Products, Services or Program Materials
							for lawful purposes only. You agree that you will not use our
							Programs, Products, Services or Program Materials in any of the
							following ways:
							<br />
						</p>
						<ul className="list-disc list-inside pl-5">
							<li>
								For fraudulent purposes or in connection with a criminal offense
								or otherwise carry out any unlawful activity
							</li>
							<li>
								To send, use or re-use any material that is illegal, offensive,
								abusive, indecent, harmful, defamatory, obscene or menacing,
								threatening, objectionable, invasive of privacy, in br/each of
								confidence, infringing of any intellectual property rights, or
								that may otherwise may injure others
							</li>
							<li>
								To send, negatively impact, or infect our Programs, Products,
								Services or Program Materials with software viruses or any other
								harmful or similar computer code designed to adversely affect
								the operation of any computer software or hardware, commercial
								solicitation, chain letters, mass mailings or any spam, whether
								intended or not
							</li>
							<li>To cause annoyance, inconvenience or needless anxiety</li>
							<li>
								To impersonate any third party or otherwise mislead as to the
								origin of your contributions
							</li>
							<li>
								To reproduce, duplicate, copy or resell any part of our
								Programs, Products, Services or Program Materials in a way that
								is not in compliance with these Terms of Use or any other
								agreement with us.
							</li>
						</ul>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								Purchases and Online Commerce.
							</span>
							<br />
							<br />
							If paying by debit card or credit card or other merchant account
							such as PayPal, you give us permission to automatically charge
							your credit or debit card as payment for your Program, Product or
							Service without any additional authorization, for which you will
							receive an electronic receipt. In the event that payment is not
							received by the due date, you will have a three (3) day grace
							period to make the payment otherwise the Program, Product or
							Services will not continue and we reserve the right to cease your
							access immediately and permanently. If you fail to make payment in
							a timely manner in accordance with these Terms of Use or
							voluntarily decide to withdraw from our Programs, Products or
							Services at any time or for any reason whatsoever, you still will
							remain fully responsible for the full cost of the Programs,
							Products and/or Services. All information obtained during your
							purchase or transaction for our Programs, Products and Services
							and all of the information that you give as part of the
							transaction, such as your name, address, method of payment, credit
							card number, and billing information, may be collected by both us
							and our payment processing company. You agree to only purchase
							goods or services for yourself or for another person for whom you
							are legally permitted to do so or for whom you have obtained the
							express consent to provide their name, address, method of payment,
							credit card number, and billing information. You agree to be
							financially responsible for all purchases made by you or someone
							acting on your behalf. You agree to use our Programs, Products,
							Services, and Program Materials for legitimate, non-commercial
							purposes only and not for speculative, false, fraudulent, or
							illegal purposes.
							<br />
							<br />
							<strong>
								Since we have a clear and explicit Refund Policy in these Terms
								of Use that you have agreed to prior to completing the purchase
								of any of our Programs, Products, or Services, we do not
								tolerate or accept any type of chargeback threat or actual
								chargeback from your credit card company.
							</strong>
							<br />
							<br />
							In the event that a chargeback is placed on a purchase or we
							receive a chargeback threat during or after your purchase, we
							reserve the right to report the incident to all three credit
							reporting agencies or to any other entity for inclusion in any
							chargeback database or for listing as a delinquent account which
							could have a negative impact on your credit report score. The
							information reported will include your name, email address, order
							date, order amount, and billing address. Chargeback abusers
							wishing to be removed from the database shall make the payment for
							the amount of the chargeback.
							<br />
							If you make a purchase from one of our affiliates, or any other
							individual or company through a link provided on or through our
							Programs, Products or Services (&ldquo;Merchant&rdquo;), all
							information obtained during your purchase or transaction and all
							of the information that you give as part of the transaction, such
							as your credit card number and contact information, may be
							collected by the merchant and their payment processing company as
							well. Your participation, correspondence or business dealings with
							any affiliate, individual or company on or through our Programs,
							Products or Services, and all purchase terms, conditions,
							representations or warranties associated with payment, refunds,
							and/or delivery related to your purchase, are solely between you
							and the Merchant. You agree that we shall not be responsible or
							liable for any loss, damage, refunds, or other matters of any sort
							that incurred as the result of such dealings with a Merchant.
							<br />
							<br />
							Payment processing companies and Merchants may have privacy and
							data collection practices that are different from ours. We have no
							responsibility or liability for these independent policies of the
							payment processing companies and Merchants. In addition, when you
							make certain purchases through our Programs, Products or Services,
							you may be subject to the additional terms and conditions of a
							payment processing company, Merchant or us that specifically apply
							to your purchase. For more information regarding a Merchant and
							its terms and conditions that may apply, visit that
							merchant&rsquo;s Website and click on its information links or
							contact the Merchant directly. You release us, our affiliates, our
							payment processing company, and Merchants from any damages that
							you incur, and agree not to assert any claims against us or them,
							arising from your purchase through or use of our Website or its
							Content.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								Cancellation/Refund Policy
							</span>
							<br />
							<br />
							Canceling your membership is as easy as it gets. You may cancel
							your subscription at any time by simply sending an email to
							cancel@rapidfireoptions.com with the subject &ldquo;Please
							Cancel&rdquo;. When you cancel your subscription, you cancel only
							future charges associated with your subscription. The cancellation
							will become effective one day before your next billing period
							starts. If your next billing date falls on the weekend or the
							following Monday, your account will be canceled on the prior
							business day (i.e Friday). If you cancel after your billing cycle
							has started, we are not able to offer you a pro-rated refund and
							you will be charged for the entire billing cycle (3 monthly or
							month-to-month based on the plan you signed up for)
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">Termination</span>
							<br />
							<br />
							You have the right to terminate your use of or participation in
							our Programs, Products or Services at any time.
							<br />
							We reserve the right in our sole discretion to refuse or terminate
							your access to our Programs, Products, Services or Program
							Materials, in full or in part, at any time, without notice, by
							sending you an email to the e-mail address you provided upon
							purchase of the Program, Product or Service. In the event of
							cancellation or termination by either of us, you will have 24
							hours to pay any and all remaining payments or balances that are
							owed to us.
							<br />
							Upon termination by either of us, we reserve the right to
							immediately refuse or terminate your access to any aspect of our
							Programs, Products, Services and/or our Program Materials,
							including but not limited to our Website, private forum, e-mail
							communications, Facebook groups, live webinars or conference
							calls, or any other method of communications related to our
							Programs, Products, Services or Program Materials at any time
							without notice and in our sole discretion.
							<br />
							All of the terms of this Terms of Use, including but not limited
							to all copyright, trademark, and intellectual property rights,
							disclaimers, limitations of liability, release of claims, and our
							Refund Policy will still apply now and in the future, even after
							termination by you or us. Furthermore, while some offerings
							promise lifetime access, this means that the content and
							information will be available to you so long as the service is
							still available.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								Dispute Resolution
							</span>
							<br />
							<br />
							It is hoped that should we ever have any differences, we could be
							able to work them out amicably through e-mail correspondence.
							However, should we be unable to seek resolution within a
							reasonable time, you agree now that the only method of legal
							dispute resolution that will be used is binding arbitration before
							a single arbitrator, selected jointly, in accordance with the
							American Arbitration Association Rules. Prior to seeking
							arbitration, you must send an email to us and include all of your
							reasons for dissatisfaction with your Program. You understand and
							agree now that the only remedy that can be awarded to you through
							arbitration is full refund of your Payment made to date. No other
							actions or financial awards of consequential damages, or any other
							type of damages, may be granted to you. We both agree now that the
							decision of the arbitrator is final and binding, and may be
							entered as a judgment into any court having the appropriate
							jurisdiction. You also agree that this document shall be construed
							with the laws of Pennsylvania.
							<br />
							<br />
							<strong>
								By purchasing our Programs, Products or Services you are
								agreeing to a modification of the statute of limitations such
								that any arbitration must begin within one (1) year of the date
								of your email to me referenced above or you waive the right to
								seek dispute resolution by arbitration or to take any other
								legal action.
							</strong>
							<br />
							<br />
							You also agree that should arbitration take place, it will be held
							in the state where Rapid Fire Options is located, and the
							prevailing party shall be entitled to all reasonable
							attorneys&rsquo; fees and all costs necessary to enforce the
							decision of the arbitrator.
							<br />
							In the event of a dispute between us, you agree to not engage in
							any conduct or communications, public or private, including on
							social media, designed to disparage us, our Company, or any of our
							Programs, Products or Services. Where requested by law or
							arbitration, of course, you are not prohibited from sharing your
							thoughts and opinions as a part of the legal process.
							<br />
							If any terms of these Terms of Use are construed to be invalid or
							unenforceable for any reason, it shall not affect the validity or
							enforceability of any other term which shall be given full force
							and effect.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								Confidentiality and Privacy.
							</span>
							<br />
							<br />
							Confidential Information. To use our Programs, Products, Services
							or Program Materials, we may seek personal data or information
							including your name, e-mail address, phone number, street address,
							billing information, birthday, preferences, interests,
							assignments, or other personally-identifying information
							(&ldquo;Confidential Information&rdquo;), or you may offer or
							provide a comment, photo, image, video or any other submission to
							us when using or participating in our Programs, Products, Services
							or Program Materials (&ldquo;Other Information&rdquo;). By
							providing such Confidential Information or Other Information to
							us, you grant us permission to use and store such information. We,
							in turn, will use our best efforts to keep your Confidential
							Information safe, secure and confidential in accordance with these
							Terms of Use and our full Privacy Policy which may be found on our
							Website. If you believe that any of your Confidential Information
							is incorrect or incomplete, please contact us as soon as possible.
							We will promptly correct any Confidential Information found to be
							incorrect.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								What We Do With Confidential Information.
							</span>
							<br />
							<br />
							We request and require various personal data and/or Confidential
							Information to understand your needs and provide you with better
							services. In addition, we may use such data and Confidential
							Information for the following reasons: (1) for internal record
							keeping, (2) to improve our Programs, Products, Services or
							Program Materials, (3) to periodically send promotions about new
							Programs, Products or Services or other special offers from which
							you may unsubscribe at any time, (4) for aggregate,
							non-identifiable data for research purposes, (5) to customize the
							respective Programs, Products or Services you purchase or use
							according to your interests and/or (6) for support or
							communication related to your Program, Product, Service or Program
							Materials.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">Storage.</span>
							<br />
							<br />
							All data and Confidential Information is stored through a data
							management system. This data and Confidential Information can only
							be accessed by those who help manage that information in order to
							deliver e-mail or otherwise contact those who would like to
							receive our correspondence. You agree and acknowledge that we,
							including but not limited to our team, staff and affiliates, and
							those who manage the data management system may have access to
							your Confidential Information.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								Confidentiality and Disclosure.
							</span>
							<br />
							<br />
							All Confidential Information will be held in confidentiality and
							will not be disclosed to third parties, except that we may
							disclose Confidential Information and personally identifiable
							information: (1) pursuant to this terms of these Terms of Use and
							Privacy Policy and our Disclaimer, (2) if we are required to do so
							by law, (3) in the good-faith belief that such action is necessary
							to conform to the law, (4) to comply with any legal process served
							on either us or our partners, sponsors, investors, or affiliates,
							(5) to protect and defend our rights or property or those of our
							users or purchasers, and/or (6) to act as immediately necessary in
							order to protect the personal safety of our users, purchasers, or
							the public. We will not sell, distribute or lease your
							Confidential Information to third parties unless we have your
							permission or are required by law to do so.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								Viewing by Others.
							</span>
							<br />
							<br />
							Note that whenever you make your Confidential Information or Other
							Information available for viewing by others such as through our
							Programs, Products, Services, or Program Materials, our Website or
							social media, the Confidential Information or Other Information
							that you share also can be seen, collected and used others, and
							therefore, we cannot be responsible for any unauthorized use by
							others of such Confidential Information or Other Information that
							you voluntarily share online or in any other manner.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">
								How We Use Cookies.
							</span>
							<br />
							<br />
							We may use the standard &ldquo;cookies&rdquo; feature of major web
							browsers. We do not set any Confidential Information in cookies,
							nor do we employ any data-capture mechanisms on our Website other
							than cookies. You may choose to disable cookies through your own
							web browser&rsquo;s settings. However, disabling this function may
							diminish your experience on the Website and some features of our
							Programs, Products, Services or Program Materials may not work as
							intended. We have no access to or control over any information
							collected by other individuals, companies or entities whose
							website or materials may be linked to our Programs, Products,
							Services or Program Materials.
						</p>
						<p className="py-3">
							<span className="text-2xl font-bold pb-2">Passwords.</span>
							<br />
							<br />
							To use certain features of our Programs, Products, Services, or
							Program Materials, you may be issued a group username and password
							or a unique individual username and password, which you will
							receive through your registration and/or purchase process. You may
							be able to change your username and/or password of your choosing.
							You are responsible for maintaining the confidentiality of the
							password and account, and are responsible for all activities
							(whether by you or by others) that occur under your password or
							account. You are not permitted to share your username and/or
							password with anyone. If we learn you have shared your username
							and/or password with another person, we reserve the right to
							immediately terminate your access to the Program, Product,
							Service, Program Materials, Website, private forum, Facebook group
							or any other related communication. It is your responsibility to
							protect your own password from disclosure to others. We cannot and
							will not be liable for any loss or damage arising from your
							failure to protect your password or account information. You are
							responsible for activities that take place using your password(s)
							and within your account. If you share your password(s) with
							anyone, they may be able to obtain access to your personal
							information at your own risk. You agree to notify us immediately
							of any unauthorized use of your password or account or any other
							br/each of security, and to ensure that you exit from your account
							at the end of each session. By using our Programs, Products and
							Services, you agree to enter true and accurate information as part
							of the registration, purchase process and otherwise. If you enter
							a false email address, we have the right to immediately deactivate
							your account. We will use our best efforts to keep your
							password(s) private and will not otherwise share your password(s)
							without your consent, except as necessary when the law requires it
							or in the good faith belief that such action is necessary,
							particularly when disclosure is necessary to identify, contact or
							br/ing legal action against someone who may be causing injury to
							others or interfering with our rights or property.
							<br />
							<br />
							<strong>
								UNDER NO CIRCUMSTANCES SHALL COMPANY OR ANY OF ITS PARENTS,
								SUBSIDIARIES, AFFILIATES, OR OTHERWISE BE LIABLE FOR DIRECT,
								INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE,
								EXEMPLARY, OR ANY OTHER DAMAGES ARISING OUT OF YOUR USE OF THE
								SITE OR SERVICE, INCLUDING BUT NOT LIMITED TO NEGLIGENCE.
								ADDITIONALLY, COMPANY IS NOT LIABLE FOR DAMAGES IN CONNECTION
								WITH (I) ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, DENIAL OF
								SERVICE, ATTACK, INTERRUPTION, DELETION, DEFECT, DELAY IN
								OPERATION OR TRANSMISSION, COMPUTER VIRUS OR LINE OR SYSTEM
								FAILURE; (II) LOSS OF REVENUE, ANTICIPATED PROFITS, BUSINESS,
								SAVINGS, GOODWILL OR DATA; AND (III) THIRD PARTY THEFT OF,
								DESTRUCTION OF, UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF
								YOUR INFORMATION OR PROPERTY, REGARDLESS OF OUR NEGLIGENCE,
								GROSS NEGLIGENCE, FAILURE OF AN ESSENTIAL PURPOSE AND WHETHER
								SUCH LIABILITY ARISES IN NEGLIGENCE, CONTRACT, TORT, OR ANY
								OTHER THEORY OF LEGAL LIABILITY. THE FOREGOING APPLIES EVEN IF
								COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF OR COULD HAVE
								FORESEEN THE DAMAGES. IN THOSE STATES THAT DO NOT ALLOW THE
								EXCLUSION OR LIMITATION OF LIABILITY FOR THE DAMAGES, OUR
								LIABILITY IS LIMITED TO THE FULLEST POSSIBLE EXTENT PERMITTED BY
								LAW. IN NO EVENT SHALL COMPANY&rsquo;S CUMULATIVE LIABILITY TO
								YOU EXCEED $100.
							</strong>
							<br />
							<br />
							Should any provision of these Terms and Conditions, Disclaimers,
							Terms of Use or Disclosures be or become invalid, illegal, or
							unenforceable under applicable law, the other provisions shall not
							be affected and shall remain in full force and effect. These Terms
							and Conditions, Disclaimers, Terms of Use or Disclosures bind and
							insure to the benefit of the parties&rsquo; successors and
							assigns. These Terms and Conditions, Disclaimers, Terms of Use or
							Disclosures are not assignable; any transfer, assignment or
							delegation by you is invalid. These Terms and Conditions,
							Disclaimers, Terms of Use or Disclosures constitute the entire
							agreement between the parties with respect to its relationship and
							supersede all prior oral or written agreements, understandings and
							representations to the extent that they relate in any way to the
							subject matter hereof. No waiver of any of the provisions shall
							bind either party unless set forth in a writing specifying such
							waiver, consent or amendment signed by both parties. The headings
							of these Terms and Conditions, Disclaimers, Terms of Use or
							Disclosures are provided for convenience only and shall not affect
							its construction or interpretation.
							<br />
							<br />
							By using the Website, you agree to these Terms and Conditions,
							Disclaimers, Terms of Use or Disclosures without modification, and
							acknowledge reading them. We reserve the right to change these
							Terms and Conditions, Disclaimers, Terms of Use or Disclosures or
							to impose new conditions on use of the Website, from time to time,
							in which case we will post the revised Terms and Conditions,
							Disclaimers, Terms of Use or Disclosures on this Website. By
							continuing to use the Website after we post any such changes means
							you accept the new Terms and Conditions, Disclaimers, Terms of Use
							or Disclosures with the modifications.
							<br />
							<br />
							If you have any questions, please contact us. Thank you.
							<br />
						</p>
						<br />
					</Fade>
				</div>
			</div>
		</Layout>
	)
}

export default TermsPage
