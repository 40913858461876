import { useStaticQuery, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'

export function usePageHero() {
	const { ecomHero } = useStaticQuery(
		graphql`
			query {
				ecomHero: file(relativePath: { eq: "chart2.jpg" }) {
					childImageSharp {
						gatsbyImageData(width: 1500, placeholder: BLURRED, formats: AUTO)
					}
				}
			}
		`
	)

	const convert = getImage(ecomHero)
	const finalBg = convertToBgImage(convert)

	return finalBg
}
